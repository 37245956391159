import { Box, Heading, Accordion } from "@chakra-ui/core"
import { graphql } from "gatsby"
import React, { FunctionComponent } from "react"
import { Container } from "../components/container"
import { Layout } from "../components/layout"
import { Title } from "../components/title"
import { AccordionItem } from "../components/accordionItem"
import { SEO } from "../components/seo"

const Distances: FunctionComponent<{ data: any }> = ({ data }) => {
  const distances = data.allPrismicDistances.edges

  return (
    <Layout>
      <Box
        position="fixed"
        h="100vh"
        w="100vw"
        bg="#fff"
        zIndex={-1}
        opacity={0.35}
      />
      <Container>
        <SEO title="TÁVOK" />
        <Title text="TÁVOK" />

        <Accordion allowToggle>
          {distances?.map((distance: any, index: number) => {
            const { data } = distance.node
            return (
              <AccordionItem
                key={index}
                title={data.title?.text}
                content={data.content.html}
              />
            )
          })}
        </Accordion>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query Distance {
    allPrismicDistances {
      edges {
        node {
          data {
            title {
              text
            }
            content {
              html
            }
          }
        }
      }
    }
  }
`

export default Distances
